<template>
  <base-section id="theme-features">
    <base-section-heading title="Platform Features">
      The tokenomics is the core of the platform. <strong>$FEK</strong> token allows you to relax and watch your wallet
      growing. However we believe this model can only work if we add utilities to the token.
      This is why we will add multiple features accessible to <strong>$FEK</strong> holders exclusively.
    </base-section-heading>
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/bg-4.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container>
        <v-row>
          <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            md="6"
          >
            <base-avatar-card
              v-bind="feature"
              align="left"
              horizontal
            >
              <p align="justify">
                {{ feature.desc }}
              </p>
            </base-avatar-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Instant Staking',
          icon: 'mdi-food',
          desc: 'No need to stake or lock up your tokens anywhere to receive rewards. Just hold $FEK tokens in your wallet and watch your balance increase.',
        },
        {
          title: 'Holders Rewards',
          icon: 'mdi-sunglasses',
          desc: 'The tokenomics apply a special tax to every buy or sell of the tokens. 2% are redistributed back to holders while 2% are burned forever reducing the total supply.',
        },
        {
          title: 'Community Driven',
          icon: 'mdi-account-supervisor-circle',
          desc: '$FEK will act as a governance token for incoming features. We believe our community is a key to success.',
        },
        {
          title: 'True Decentralization',
          icon: 'mdi-atom',
          desc: 'The $FEK token smart contract is complete at launch. There was no ICO, no pre-sale, and no fundraising of any kind. ',
        },
        {
          title: 'Gaming',
          icon: 'mdi-gamepad-circle',
          desc: 'We believe in gaming and we plan to launch simple games where $FEK holders will compete together and bet their tokens.',
        },
        {
          title: 'NFT',
          icon: 'mdi-draw',
          desc: 'In addition to games, we would like to create NFT rewards for $FEK tokens.',
        },
      ],
    }),
  }
</script>
